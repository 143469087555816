import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Card, CardContent, CardMedia, CircularProgress, Container, Typography, Pagination } from '@mui/material';
import { config } from '../../config';
import { authHeader } from '../../utils';
import { Search } from '../../components';
import { Option } from '../../model'

// TODO: single place details page

type ResultsPageProps = {
    districts: Option[],
    types: string[],
    gardenSunlights: string[]
    foodTypes: string[]
}

const ResultsPage: React.FC<ResultsPageProps> = ({
    districts: availableDistricts, types: availableTypes, gardenSunlights: availableGardenSunlights, foodTypes: availableFoodTypes
}) => {

    const [loading, setLoading] = useState<boolean>(false);

    const baseUrl = config.baseUrl;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    // TODO: sanitize
    const districtsQuery = (params.get('districts'));
    const typeQuery = (params.get('type'));
    const gardenQuery = (params.get('garden'));
    const gardenSunlightQuery = (params.get('garden_sunlights'));
    const foodTypeQuery = (params.get('food_types'));

    const [data, setData] = useState<DataItem[]>([]);
    const [meta, setMeta] = useState<ResponseMeta | null>(null);
    const [page, setPage] = useState<number>(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const query = constructQuery(`${baseUrl}/api/places`, page, gardenQuery === 'true', typeQuery || '', districtsQuery || '', gardenSunlightQuery || '', foodTypeQuery || '');

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            const response = await axios.get<APIResponse>(query, {
                headers: authHeader
            })
            const meta = response.data.meta
            const data = response.data.data.map(mapResponseItemToDataItem);
            setMeta(meta);
            setData(data);
            setLoading(false);
        }
        fetchData()
            .catch((error) => {
                setLoading(false);
                console.log(error);
            });
    }, [query])

    const navigate = useNavigate();

    const handleSearch = (type?: string, district?: Option[], garden?: boolean, gardenSunlight?: string[], foodTypes?: string[]) => {
        const queryParams = new URLSearchParams();
        if (type) {
            queryParams.append('type', type);
        }
        if (district?.length) {
            queryParams.append('districts', district.map(({ value }) => value).join(','));
        }
        if (garden) {
            queryParams.append('garden', garden.toString());
        }
        if (gardenSunlight?.length) {
            queryParams.append('garden_sunlights', gardenSunlight.join(','));
        }
        if (foodTypes?.length) {
            queryParams.append('food_types', foodTypes.join(','));
        }
        navigate(`/results?${queryParams.toString()}`);
    };

    return (
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
            }}
        >

            <Search
                districts={availableDistricts}
                types={availableTypes}
                handleSearch={handleSearch}
                expandable={true}
                gardenSunlights={availableGardenSunlights}
                foodTypes={availableFoodTypes}
            />

            <Container maxWidth="sm"
                sx={{
                    marginTop: 4,
                }}
            >
                <Box sx={{
                    marginBottom: 4,
                }}>
                    <Typography gutterBottom variant="h4" component="div" color="text.primary">
                        Wybrano:
                    </Typography>

                    {typeQuery &&
                        <Typography variant="body1" color="text.secondary">
                            Cel: {typeQuery}
                        </Typography>}

                    {gardenQuery &&
                        <Typography variant="body1" color="text.secondary">
                            Ogródek: {gardenQuery ? "tak" : "nie"}
                        </Typography>
                    }
                    {districtsQuery &&
                        <Typography variant="body1" color="text.secondary">

                            Dzielnice: {districtsQuery}
                        </Typography>
                    }
                    {gardenSunlightQuery &&
                        <Typography variant="body1" color="text.secondary">
                            Słońce w ogródku: {gardenSunlightQuery}
                        </Typography>
                    }
                    {foodTypeQuery &&
                        <Typography variant="body1" color="text.secondary" gutterBottom>
                            Rodzaj jedzenia: {foodTypeQuery}
                        </Typography>
                    }
                </Box>

                {loading ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: '#595959' }} />
                    </Box>
                    : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, marginBottom: 2 }}>
                            {data.map((result, index) => (
                                <Card key={index} variant="outlined">
                                    {result.image &&
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={result.image}
                                        />
                                    }
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {result.name}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            {result.description}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Cel: {result.types}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Dzielnica: {result.district}
                                        </Typography>

                                        <Typography variant="body2" color="text.secondary">
                                            Ogródek: {result.garden ? "tak" : "nie"}
                                        </Typography>

                                        {gardenSunlightQuery && (
                                            <Typography variant="body2" color="text.secondary">
                                                Słońce w ogródku: {result.gardenSunlights}
                                            </Typography>
                                        )}
                                        {foodTypeQuery && (
                                            <Typography variant="body2" color="text.secondary">
                                                Rodzaj jedzenia: {result.foodTypes}
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    )}
            </Container>
            <Pagination count={meta?.pagination.pageCount} page={page} onChange={handleChange} sx={{ marginBottom: 2 }} />
        </Box>

    );
};

export default ResultsPage;

const constructQuery = (baseUrl: string, page: number, garden?: boolean, type?: string, districts?: string, gardenSunlights?: string, foodTypes?: string) => {
    const url = new URL(baseUrl);
    const params = new URLSearchParams();

    params.append('pagination[page]', page.toString());

    if (garden)
        params.append('filters[garden][$eq]', garden.toString());
    if (type)
        params.append('filters[types][name][$eq]', type);
    if (districts) {
        const districtsArray = districts.split(',');
        districtsArray.forEach((district, index) => {
            params.append(`filters[district][name][$in][${index}]`, district);
        });
    }
    if (gardenSunlights) {
        const gardenSunglightArray = gardenSunlights.split(',');
        gardenSunglightArray.forEach((gardenSunglight, index) => {
            params.append(`filters[garden_sunlights][name][$in][${index}]`, gardenSunglight);
        });
    }
    if (foodTypes) {
        const foodTypesArray = foodTypes.split(',');
        foodTypesArray.forEach((foodType, index) => {
            params.append(`filters[food_types][name][$in][${index}]`, foodType);
        });
    }
    params.append('populate[0]', 'image');
    params.append('populate[1]', 'district');
    params.append('populate[2]', 'types');
    params.append('populate[3]', 'garden_sunlights');
    params.append('populate[4]', 'food_types');
    url.search = params.toString();
    return url.toString();
}

type DataItem = {
    name: string;
    description?: string;
    garden?: boolean;
    types?: string;
    image?: string;
    district?: string;
    gardenSunlights?: string;
    foodTypes?: string
}

type APIResponse = {
    data: ResponseItem[]
    meta: ResponseMeta
}

type Data = {
    attributes: {
        name: string;
    }
}

type ResponseMeta = {
    pagination: {
        page: number;
        pageCount: number;
        pageSize: number;
        total: number;
    }
}

type ResponseItem = {
    attributes: {
        name: string;
        description?: string;
        garden?: boolean;
        types?: {
            data?: Data[]
        },
        image: {
            data?: {
                attributes: {
                    url: string;
                    formats: {
                        small: {
                            url: string;
                        },
                        thumbnail: {
                            url: string
                        }
                    }
                }
            }
        },
        district?: {
            data?: Data
        },
        garden_sunlights?: {
            data?: Data[]
        },
        food_types?: {
            data?: Data[]
        },
    }
}
const mapResponseItemToDataItem = (responseItem: ResponseItem): DataItem => ({
    ...responseItem.attributes,
    image: responseItem.attributes.image.data ? `${config.baseUrl}${responseItem.attributes.image.data.attributes.formats.small.url}` : undefined,
    district: responseItem.attributes.district?.data?.attributes.name || '',
    types: responseItem.attributes.types
        ? responseItem.attributes.types.data?.map(({ attributes }) => attributes.name).join(', ')
        : "",
    gardenSunlights: responseItem.attributes.garden_sunlights
        ? responseItem.attributes.garden_sunlights.data?.map(({ attributes }) => attributes.name).join(', ') : '',
    foodTypes: responseItem.attributes.food_types
        ? responseItem.attributes.food_types.data?.map(({ attributes }) => attributes.name).join(', ') : ''
});
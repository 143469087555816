
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home-page/home-page';
import ResultsPage from './pages/results-page/results-page';
import { Container, Typography } from '@mui/material';
import { Option, fetchDistricts, fetchFoodTypes, fetchGardenSunlights, fetchTypes } from './model'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ArrowBack } from '@mui/icons-material';


type PageParams = {
  types: string[],
  districts: Option[],
  gardenSunlights: string[]
  foodTypes: string[]
}

const defaultDistrict = { label: 'Dowolna', value: "" };

const App: React.FC = () => {

  const [pageParams, setPageParams] = useState<PageParams>({
    types: [],
    districts: [defaultDistrict],
    gardenSunlights: [],
    foodTypes: []
  });

  useEffect(() => {
    Promise.all([
      fetchDistricts(),
      fetchTypes(),
      fetchGardenSunlights(),
      fetchFoodTypes()
    ]).then(([apiDistricts, apiTypes, apiGardenSunlights, apiFoodTypes]) => {
      setPageParams({
        types: apiTypes,
        districts: [defaultDistrict, ...apiDistricts],
        gardenSunlights: apiGardenSunlights,
        foodTypes: apiFoodTypes
      });
    }).catch(console.error);
  }, [])

  return (
    <>

      <Container
        sx={{
          position: 'fixed',
          top: '0px',
          background: 'white',
          borderBottom: '1px solid #595959',
          width: '100%',
          maxWidth: '100% !important',
          zIndex: 10,
        }}
      >
        <a href="/" style={{ textDecoration: 'none', }}>
          <Typography variant="body1" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '8px' }}>
            <ArrowBack />
            Wstecz
          </Typography>
        </a>

        <Typography
          variant="h1"
          sx={{
            flex: 1,
            marginBottom: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontWeight: '800',
            fontSize: '42px',
            fontFamily: '"Poppins", sans-serif;',
            color: '#595959'
          }}>
          GDZIE IDZIEMY?
        </Typography>
      </Container>
      <Container sx={{ marginTop: 20, '@media (max-width: 600px)': { marginTop: 15 } }}>
        <Router>
          <Routes>
            <Route path="/" element={<HomePage districts={pageParams.districts} types={pageParams.types} gardenSunlights={pageParams.gardenSunlights} foodTypes={pageParams.foodTypes} />} />
            <Route path="/results" element={<ResultsPage districts={pageParams.districts} types={pageParams.types} gardenSunlights={pageParams.gardenSunlights} foodTypes={pageParams.foodTypes} />} />
          </Routes>
        </Router>
      </Container>
    </>
  );
};

export default App;
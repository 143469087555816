import React, { ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Checkbox, Button, SelectChangeEvent, Box, FormControlLabel, Typography, Card, CardContent } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { Option } from '../../model'
import { useLocation } from 'react-router-dom';

type SearchProps = {
    districts: { label: string, value: string }[],
    types: string[],
    handleSearch: (type?: string, district?: Option[], garden?: boolean, gardenSunlights?: string[], foodTypes?: string[]) => void,
    gardenSunlights?: string[],
    foodTypes?: string[],
    expandable?: boolean
}

export const Search: React.FC<SearchProps> = ({
    districts,
    types,
    handleSearch,
    gardenSunlights = [],
    foodTypes = [],
    expandable = false
}) => {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const districtsQuery = params.get('districts');
    const typeQuery = params.get('type');
    const gardenQuery = params.get('garden');
    const gardenSunglightQuery = params.get('garden_sunlights');
    const foodTypeQuery = params.get('food_types');

    const defaultDistrictValue = districtsQuery ? districtsQuery.split(',').map((district => ({ label: district, value: district }))) : [];
    const defaultGardenSunlights = gardenSunglightQuery ? gardenSunglightQuery.split(',') : []
    const defaultFoodType = foodTypeQuery ? foodTypeQuery.split(',') : []

    const [selectedGarden, setSelectedGarden] = useState<boolean>(gardenQuery === 'true');
    const [selectedType, setSelectedType] = useState<string>(typeQuery || '');
    const [selectedDistricts, setSelectedDistricts] = useState<Option[]>(defaultDistrictValue);
    const [selectedGardenSunlights, setSelectedGardenSunlights] = useState<string[]>(defaultGardenSunlights);
    const [selectedFoodTypes, setSelectedFoodTypes] = useState<string[]>(defaultFoodType);

    const [extendedFilters, setExtendedFilters] = useState<boolean>(false);

    const handleGardenChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedGarden(event.target.checked);
    }

    const handleTypeChange = (event: SelectChangeEvent<string>) => {
        setSelectedType(event.target.value);
    };

    const handleDistrictChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedDistricts((event.target.value as string[]).map(value => districts.find(d => d.value === value)!));
    };

    const handleGardenSunlightChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedGardenSunlights(event.target.value as string[]);
    }

    const handleFoodTypeChange = (event: SelectChangeEvent<string[]>) => {
        setSelectedFoodTypes(event.target.value as string[])
    }

    return (
        <Card variant="outlined">
            <CardContent >
                <Typography fontSize={32} variant="h1" gutterBottom>Gdzie idziemy?</Typography>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', gap: 2,
                    '@media (max-width:768px)': {
                        flexDirection: 'column',
                    },
                }}>

                    <FormControl
                        sx={{
                            minWidth: 150,
                            '@media (max-width:768px)': {
                                minWidth: 250
                            },
                        }}>
                        <InputLabel >Cel:</InputLabel>
                        <Select autoWidth label="Cel:" value={selectedType} onChange={handleTypeChange}>
                            <MenuItem value="">Wybierz cel</MenuItem>
                            {types.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{
                            minWidth: 150,
                            '@media (max-width:768px)': {
                                minWidth: 250
                            },
                        }}>
                        <InputLabel >Dzielnice:</InputLabel>
                        <Select multiple autoWidth label="Dzielnica:" value={selectedDistricts.map(({ value }) => value) as any} onChange={handleDistrictChange}>
                            {districts.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={selectedGarden} onChange={handleGardenChange} />}
                        label="Ogródek?"
                    />
                    <Button
                        onClick={() => handleSearch(selectedType, selectedDistricts, selectedGarden, selectedGardenSunlights, selectedFoodTypes)}
                        variant="contained"
                        sx={{
                            backgroundColor: '#595959',
                            "&:hover": {
                                backgroundColor: '#797979',
                            }
                        }}>
                        Szukaj
                    </Button>
                </Box>

                {expandable &&
                    <>
                        <Button
                            sx={{ marginTop: 2 }}
                            onClick={() => setExtendedFilters(!extendedFilters)}
                            variant='text'
                            startIcon={extendedFilters
                                ? <KeyboardArrowUp sx={{
                                    color: '#595959',
                                    "&:hover": {
                                        color: '#797979',
                                    }
                                }} />
                                : <KeyboardArrowDown sx={{
                                    color: '#595959',
                                    "&:hover": {
                                        color: '#797979',
                                    }
                                }} />}>
                            <Typography variant="body1" color="text.secondary">Wyszukiwanie zaawansowane</Typography>
                        </Button>
                        {extendedFilters &&
                            <Box
                                sx={{
                                    display: 'flex', justifyContent: 'start', alignItems: 'flex-start', marginTop: 2, flexDirection: 'column',
                                    '@media (max-width:768px)': {
                                        alignItems: 'unset'
                                    },
                                }}
                            >
                                <FormControl sx={{ minWidth: 250, marginBottom: 2 }}>
                                    <InputLabel >Słońce w ogródku:</InputLabel>
                                    <Select multiple autoWidth label="Słońce w ogródku:" value={selectedGardenSunlights} onChange={handleGardenSunlightChange}>
                                        {gardenSunlights.map((label, index) => (
                                            <MenuItem key={index} value={label}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ minWidth: 250 }}>
                                    <InputLabel >Rodzaj jedzenia:</InputLabel>
                                    <Select multiple autoWidth label="Rodzaj jedzenia:" value={selectedFoodTypes} onChange={handleFoodTypeChange}>
                                        {foodTypes.map((label, index) => (
                                            <MenuItem key={index} value={label}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </>

                }
            </CardContent>
        </Card >
    )
}
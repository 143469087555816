import React, { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'
import { Box, Card, CardContent } from '@mui/material';
import { config as appConfig } from '../../config';
import { Option } from '../../model'
import { authHeader } from '../../utils';
import { Search } from '../../components';


const fetchCopyConfig = async () =>
    await axios.get(`${appConfig.baseUrl}/api/app-config`, {
        headers: authHeader
    }).then((response) => response.data.data.attributes);


type HomePageProps = {
    districts: Option[],
    types: string[],
    gardenSunlights: string[]
    foodTypes: string[]
}


const HomePage: React.FC<HomePageProps> = ({ districts, types, gardenSunlights, foodTypes }) => {
    const [copyConfig, setCopyConfig] = useState<any>(null);

    useEffect(() => {
        fetchCopyConfig()
            .then(
                setCopyConfig
            )
            .catch(console.error);
    }, [])

    const navigate = useNavigate();


    const handleSearch = (type?: string, district?: Option[], garden?: boolean, gardenSunlight?: string[], foodTypes?: string[]) => {
        const queryParams = new URLSearchParams();
        if (type) {
            queryParams.append('type', type);
        }
        if (district?.length) {
            queryParams.append('districts', district.map(({ value }) => value).join(','));
        }
        if (garden) {
            queryParams.append('garden', garden.toString());
        }
        if (gardenSunlight?.length) {
            queryParams.append('garden_sunlights', gardenSunlight.join(','));
        }
        if (foodTypes?.length) {
            queryParams.append('food_types', foodTypes.join(','));
        }

        navigate(`/results?${queryParams.toString()}`);
    };

    return (
        <Box
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Search
                districts={districts}
                types={types}
                handleSearch={handleSearch}
                expandable={true}
                gardenSunlights={gardenSunlights}
                foodTypes={foodTypes}
            />

            {copyConfig &&
                <>
                    <Card variant="outlined" sx={{ marginTop: 8 }}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', '&>p': { color: 'rgba(0, 0, 0, 0.6)' } }}>
                            <ReactMarkdown children={copyConfig.copy} />
                            <br />
                            <ReactMarkdown children={copyConfig.coffee} />
                        </CardContent>
                    </Card>

                    <Box sx={{
                        position: 'relative',
                        top: '-32px'
                    }}>
                        <a
                            href="https://buycoffee.to/gdzieidziemy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {/* <img src="https://buycoffee.to/btn/buycoffeeto-btn-primary-outline.svg" style={{    width: "176px" }} alt="Postaw mi kawę na buycoffee.to" /> */}
                            <img
                                src={`${process.env.PUBLIC_URL}/coffee.gif`}
                                style={{ width: 176 + 'px' }}
                                alt="Postaw mi kawę na buycoffee.to" />
                        </a>
                    </Box>
                    <Box sx={{
                        marginTop: -5,
                        '&>p': { color: 'rgba(0, 0, 0, 0.6)' }
                    }}>
                        <ReactMarkdown children={copyConfig.contact} />
                    </Box>
                </>
            }
        </Box>
    )
};

export default HomePage;
import axios from "axios"
import { authHeader } from '../../utils';
import { config } from '../../config';

export type APICollection = { attributes: { name: string, createdAt: string, updatedAt: string, publishedAt: string } }

export const fetchDistricts = async () =>
    await axios.get(`${config.baseUrl}/api/districts`, {
        headers: authHeader
    }).then((response) =>
        response.data.data.map(({ attributes }: APICollection) => ({ label: attributes.name, value: attributes.name }))
    )

export const fetchTypes = async () =>
    await axios.get(`${config.baseUrl}/api/types`, {
        headers: authHeader
    }).then((response) =>
        response.data.data.map(({ attributes }: APICollection) => attributes.name)
    )

export const fetchGardenSunlights = async () =>
    await axios.get(`${config.baseUrl}/api/garden-sunlights`, {
        headers: authHeader
    }).then((response) => response.data.data.map(({ attributes }: APICollection) => attributes.name)

    )

export const fetchFoodTypes = async () =>
    await axios.get(`${config.baseUrl}/api/food-types`, {
        headers: authHeader
    }).then((response) => response.data.data.map(({ attributes }: APICollection) => attributes.name)

    )